import React from 'react';
import {__version} from "amazon-ivs-web-broadcast";

export default function Header() {
    return (
        <header className="container" style={{ display: 'flex' }}>
            <img src={"/assets/img/logo.png"} style={{ width: '100px', margin: '15px 20px' }} />
            <p style={{ display: 'flex', alignItems: 'center' }}>タイトルが入ります</p>
        </header>
    );
}
