import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import Button from "../../components/Button";
import {useState} from "react";
import StageStatus from "../../components/StageStatus";
import {getParticipantToken} from "../../features/streaming/functions/participantToken";
// import './SelectSourcePage.css';

export default function SelectSourcePage() {
    const navigate = useNavigate();
    const location = useLocation();
    const [source, setSource] = useState('');
    const [showModal, setShowModal] = useState(false); // モーダルの表示状態
    const [token, setToken] = useState(''); // 取得したトークンを保持

    // 名前情報の取得
    const userName = location.state ? location.state.name : 'UnknownUser';

    // 配信開始
    const startPublish = async () => {
        setSource('camera');
        setShowModal(false);
        navigate(
            '/publisher',
            {
                state: {
                    name: userName,
                    source: source
                }
            }
        );
    }

    const startWhipPublish = async () => {
        console.log(location);
        const capabilities = ["PUBLISH"];
        const attributes = {
            "priority": "1",
            "name": 'webRTC',
            "hash": 'webRTC'
            
        };
        const token = await getParticipantToken(capabilities, attributes);

        setToken(token); // トークンを状態に保存
        setShowModal(true); // モーダルを表示
    }

    // コピー機能を実装する関数
    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            alert('コピーしました！');
        }, () => {
            alert('コピーに失敗しました');
        });
    }

    const handleClose = () => {
        setShowModal(false);
    };

    return (
        <div>
            <div className="input-wrap">
                <div className="input-content-wrap input">
                    <StageStatus/>
                    <div className='input-content'>
                        <div className='text-right'>
                            <div className='status authority'><p>管理者モード</p></div>
                        </div>
                        <div className='input-content-main'>
                            <div className={"input-content-name"}>
                                <h2 className='input-text'>こんにちは、{userName}さん</h2>
                                <p className='input-sub-text'>配信が開始されていません。<br/>次の方法で配信を開始してください。
                                </p>
                                <div className='input-inline-before'>
                                    <div style={{marginTop: '20px'}}>
                                        <Button onClick={startPublish} className={"blue-button inline-button"}>カメラを使って配信を開始する</Button>
                                    </div>
                                    <div style={{marginTop: '20px'}}>
                                        <Button onClick={startWhipPublish} className={"blue-button inline-button"}>WebRTC（WHIP）を使って配信する</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showModal && (
                <div className="modal">
                    <div className='modal-bg'></div>
                    <div className="modal-content-wrap setting">
                        <div className='modal-content'>
                            <div className='modal-content-main'>
                                {/* ここから */}
                                <div className='modal-content-setting'>
                                    <div className='setting-info-wrap'>
                                        <div className="setting-info-inner">
                                            <h2 className="setting-info-title">WebRTC（WHIP）に対応したエンコーダーに下記の設定を行って配信開始してください</h2>
                                            <div className="setting-info-item">
                                                <div className='setting-info-item-title'>
                                                    <a className={'modal-link'} href="http://52.197.30.220/players/ivs.html" target={'_blank'}>
                                                        外部リソース取り込み画面を開く<i className="fa-solid fa-arrow-up-right-from-square"></i>
                                                    </a>
                                                </div>
                                                <div className='setting-info-item-title'>
                                                    <div className="setting-info-container">
                                                        <h2>サーバー</h2>
                                                        <div className="setting-info-icon"
                                                             onClick={() => copyToClipboard('https://global.whip.live-video.net')}>
                                                            <svg viewBox="0 0 680 800" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M240 640C218 640 199.167 632.167 183.5 616.5C167.833 600.833 160 582 160 560V80C160 58 167.833 39.1667 183.5 23.5C199.167 7.83333 218 0 240 0H600C622 0 640.833 7.83333 656.5 23.5C672.167 39.1667 680 58 680 80V560C680 582 672.167 600.833 656.5 616.5C640.833 632.167 622 640 600 640H240ZM240 560H600V80H240V560ZM80 800C58 800 39.1667 792.167 23.5 776.5C7.83333 760.833 0 742 0 720V160H80V720H520V800H80Z"
                                                                    fill="#494949"/>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="setting-info-item-content">https://global.whip.live-video.net/
                                                    </div>
                                                </div>
                                                <div className='setting-info-item-title'>
                                                    <div className="setting-info-container">
                                                        <h2>トークン</h2>
                                                        <div className="setting-info-icon"
                                                             onClick={() => copyToClipboard(token)}>
                                                            <svg viewBox="0 0 680 800" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M240 640C218 640 199.167 632.167 183.5 616.5C167.833 600.833 160 582 160 560V80C160 58 167.833 39.1667 183.5 23.5C199.167 7.83333 218 0 240 0H600C622 0 640.833 7.83333 656.5 23.5C672.167 39.1667 680 58 680 80V560C680 582 672.167 600.833 656.5 616.5C640.833 632.167 622 640 600 640H240ZM240 560H600V80H240V560ZM80 800C58 800 39.1667 792.167 23.5 776.5C7.83333 760.833 0 742 0 720V160H80V720H520V800H80Z"
                                                                    fill="#494949"/>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div className="setting-info-item-content">
                                                        {token}
                                                    </div>
                                                </div>
                                                <div className='setting-info-inline'>
                                                    <Button onClick={handleClose} className={'blue-button'}
                                                            width={'30'}>閉じる</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
