import React from 'react';
import Header from './components/Header.js';
import AppRoutes from "./routes/AppRoutes";

function App() {
  return (
      // <LocalMediaProvider>
      //   <StageProvider>
            <div>
                <Header />
                <AppRoutes />
            </div>
          // {/*<div className="content container">*/}
          // {/*  <LocalMedia />*/}
          // {/*  <hr />*/}
          // {/*  <StageParticipants />*/}
          // {/*</div>*/}
          // {/*<hr />*/}
          // {/*<MediaControls />*/}
      //   </StageProvider>
      // </LocalMediaProvider>
  );
}

export default App;
