import React, {useEffect, useRef, useState} from 'react';

export default function MutedVideoScreen({stageStream, className,stageJoined}) {
    console.log(className)
    const videoRef = useRef(null);
    const [mutedVideoHeight, setScreenHeight] = useState();

    useEffect(() => {
        if (videoRef.current && stageStream) {
            videoRef.current.srcObject = new MediaStream([stageStream.mediaStreamTrack]);
        }
    }, [videoRef, stageStream]);

    return (
        <div className={'muted-screen-wrap'}>
            <div className={`muted-screen ${(className === 'small') || (stageJoined) ? 'joined-muted-local-video' : ''}`} style={{height: `${mutedVideoHeight}px`}}>
                <div className="muted-screen-icon">{(className === 'small') || (stageJoined) ?
                    <i className="fa-solid fa-circle-user fa-3x" style={{color:'#e1e4ed'}}></i> :
                    <i className="fa-solid fa-circle-user fa-7x" style={{color:'#e1e4ed'}}></i>}</div>
            </div>
            <video ref={videoRef} autoPlay playsInline className={(className === 'small') || (stageJoined) ? 'low-screen' : 'top-screen'}
                   style={{background: '#666', height: 'auto', borderRadius: '20px',aspectRatio:'16/9'}}/>
        </div>
    );
}
