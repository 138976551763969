import {useState} from "react";
import {useNavigate} from "react-router-dom";
import StageStatus from "../../components/StageStatus";
import Button from "../../components/Button";
import '../../App.css';

export default function TopPage() {
    const [name, setName] = useState('');
    const navigate = useNavigate();

    const handleSubmit = () => {
        navigate(
            '/viewer',
            {state: {name: name}}
        );
    };

    return (
        <div>
            <div className="input-wrap">
                <div className="input-content-wrap input">
                    <StageStatus/>
                    <div className='input-content'>
                        <div className='text-right'>
                            <div className='status authority'><p>参加者モード</p></div>
                        </div>
                        <div className='input-content-main'>
                            <div className={"input-content-name"}>
                                <p className='input-text'>名前を入力してください</p>
                                <div className='input-inline'>
                                    <form className={"name-form"}>
                                        <div>
                                            <input
                                                type="text"
                                                name={"name"}
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                placeholder="名前を入力してください"
                                            />
                                        </div>
                                        <div style={{marginTop:'20px'}}>
                                            <Button onClick={handleSubmit} className={"blue-button"} width={'50'}>参加</Button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}
