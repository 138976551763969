import {createContext} from "react";
import {USER_TYPE} from "../../../constants/permissions";

export const PermissionContext = createContext({
    permissions: {
        userType: USER_TYPE.VIEWER,
        canStreaming: false,
        canScreenShare: false,
        canRequestParticipation: false,
        canReceiveRequestParticipation: false,
        canSelectMainStream: false,
        canDeleteStream: false
    },
});
