import {ACTIONS} from "../constants/actions";

// ステージ参加のリクエスト
export function requestParticipation (userName, userHash, connection) {
    const payload = {
        "Action": "SEND_MESSAGE",
        "RequestId": "HOGE",
        "Content": "Stage participation request",
        "Attributes": {
            "action": ACTIONS.REQUEST_PARTICIPATION,
            "userName": userName,
            "userHash": userHash
        }
    };
    connection.send(JSON.stringify(payload));
}

// ステージ参加の承認
export function allowParticipation (userHash, connection) {
    const payload = {
        "Action": "SEND_MESSAGE",
        "RequestId": "HOGE",
        "Content": "Allowed to join the stage",
        "Attributes": {
            "action": ACTIONS.ALLOW_PARTICIPATION,
            "userHash": userHash
        }
    };
    connection.send(JSON.stringify(payload));
}

// ステージ参加の拒否
export function denyParticipation (userHash, connection) {
    const payload = {
        "Action": "SEND_MESSAGE",
        "RequestId": "HOGE",
        "Content": "denied to join the stage",
        "Attributes": {
            "action": ACTIONS.DENY_PARTICIPATION,
            "userHash": userHash
        }
    };
    connection.send(JSON.stringify(payload));
}
// ステージ参加の通知
export function participationNotification (userHash, connection) {
    const payload = {
        "Action": "SEND_MESSAGE",
        "RequestId": "HOGE",
        "Content": "join the stage",
        "Attributes": {
            "action": ACTIONS.PARTICIPATION_NOTIFICATION,
            "userHash": userHash
        }
    };
    connection.send(JSON.stringify(payload));
}
// メイン画面通知
export function UserHashForMainScreen (userHashForSortChange, connection) {
    const payload = {
        "Action": "SEND_MESSAGE",
        "RequestId": "HOGE",
        "Content": "userHashForSortChange",
        "Attributes": {
            "action": ACTIONS.USERHASHFORSORTCHANGE,
            "userHashForSortChange": userHashForSortChange
        }
    };
    connection.send(JSON.stringify(payload));
}

// ステージからの強制退出
export function forceExitStage (userHash, connection) {
    const payload = {
        "Action": "SEND_MESSAGE",
        "RequestId": "HOGE",
        "Content": "force exit from the stage",
        "Attributes": {
            "action": ACTIONS.EXIT_STAGE,
            "userHash": userHash
        }
    };
    connection.send(JSON.stringify(payload));
}

// 配信の並び順変更
export function changeStreamSort(userHash, connection) {
    const payload = {
        "Action": "SEND_MESSAGE",
        "RequestId": "HOGE",
        "Content": "change the stage stream sort",
        "Attributes": {
            "action": ACTIONS.SORT_CHANGE,
            "userHash": userHash
        }
    };
    connection.send(JSON.stringify(payload));
}
