import React, { createContext, useState, useEffect, useRef } from 'react';
import useStage from '../hooks/useStage';
import useScreenShareStage from '../hooks/useScreenShareStage';

const defaultStageContext = {
    joinStage: undefined,
    stageJoined: undefined,
    leaveStage: undefined,
    changeJoinProperties: undefined,
    participants: []
};

const defaultScreenShareStageContext = {
    screenShareStageJoined: undefined,
    publishScreenShare: undefined,
    unPublishScreenShare: false,
};

export const StageContext = createContext({
    ...defaultStageContext,
    ...defaultScreenShareStageContext,
});

export default function StageProvider({ children }) {
    const { joinStage, stageJoined, leaveStage, changeJoinProperties, participants } = useStage();
    const { publishScreenShare, unPublishScreenShare, screenShareStageJoined } = useScreenShareStage();

    const state = {
        joinStage,
        stageJoined,
        leaveStage,
        changeJoinProperties,
        participants,
        screenShareStageJoined,
        publishScreenShare,
        unPublishScreenShare,
    };

    return <StageContext.Provider value={state}>{children}</StageContext.Provider>;
}
