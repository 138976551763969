import Button from "../../../components/Button";
import Icon from "../../../components/Icon";
import React, {useContext, useEffect, useState} from "react";
import Select from "../../../components/Select";
import {StreamType} from "amazon-ivs-web-broadcast";
import {getParticipantToken} from "../functions/participantToken";
import {LocalMediaContext} from "../contexts/LocalMediaContext";
import useStage from "../hooks/useStage";
import { useLocalMedia } from '../hooks/useLocalMedia.js';
import {StageContext} from "../contexts/StageContext";
import {PermissionContext} from "../../permission/contexts/PermissionContext";
import {ChatContext} from "../../chat/contexts/ChatContext";
import {USER_TYPE} from "../../../constants/permissions";
import {useLocation, useNavigate} from "react-router-dom";


export default function StreamSettings() {
    const {
        permissions,
        updatePermissions,
        hash,
        name,
        audioDevices,
        videoDevices,
        currentAudioDevice,
        currentVideoDevice,
        updateLocalAudio,
        updateLocalVideo,
        localVideoMuted,
        setLocalVideoMuted
    } = useContext(LocalMediaContext);

    const {
        joinStage,
        stageJoined,
        leaveStage,
        publishScreenShare,
        unPublishScreenShare,
        screenShareStageJoined
    } = useContext(StageContext);

    const {
        sendRequestParticipation,
        participationRequests,
        sendAllowParticipation,
        sendDenyParticipation,
        sendParticipationNotification,
        connectionRedyState,
        sendForceExitStage
    } = useContext(ChatContext);

    const [audioMuted, setAudioMuted] = useState(true);
    // const [videoMuted, setVideoMuted] = useState(true);
    const [requestingParticipation, setRequestingParticipation] = useState(false);
    const [dispalySettingDetail, setDisplaySettingDetail] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    if (currentAudioDevice && audioMuted !== currentAudioDevice.isMuted) {
        setAudioMuted(currentAudioDevice.isMuted);
    }
    const handleRequestParticipation = async () => {
        setRequestingParticipation(true);
        await sendRequestParticipation();
        // 申請が完了した後に状態を更新する場合は、ここで状態を変更します
    };

    function onClickScreenShare() {
        if (screenShareStageJoined) {
            // 画面共有中なら閉じる
            unPublishScreenShare();
        } else {
            // 画面共有していなければ開始する
            publishScreenShare();
        }
    }

    function toggleDeviceMute(device) {
        device.setMuted(!device.isMuted);
        if (device.streamType === StreamType.VIDEO) {
            setLocalVideoMuted(device.isMuted)
            // setVideoMuted(device.isMuted);
        } else {
            setAudioMuted(device.isMuted);
        }
    }

    async function handleJoin() {
        console.log('stageJoined', stageJoined);
        if (stageJoined) {
            console.log('ステージ退出')
            if (permissions.userType === USER_TYPE.VIEWER) {
                // ビュワーなら退出時に配信不可の権限に変更する
                setRequestingParticipation(false);
                updatePermissions({canStreaming: false});
                // ステージ参加後、参加通知を送信
                await sendParticipationNotification(hash);
            }
            // ステージ退出
            setRequestingParticipation(false);
            leaveStage();
        } else {
            console.log('ステージ参加')
            setRequestingParticipation(false);
            let capabilities = ["PUBLISH", "SUBSCRIBE"];
            if (!permissions.canStreaming) {
                // ストリーム不可ならサブスクライブ権限のみ
                capabilities = ["SUBSCRIBE"];
            }
            const attributes = {
                "hash": hash,
                "name": name
            };
            // ステージ参加
            joinStage(capabilities, attributes);
            // ステージ参加後、参加通知を送信
            await sendParticipationNotification(hash);
        }
    }

    function exitStaging() {
        handleJoin();

        if (permissions.userType === 'viewer') {
            navigate('/');
        }
    }


    useEffect(() => {
        if (permissions.userType === 'viewer') {
            if (connectionRedyState) {
                const waitForReadyState = () => {
                    if (connectionRedyState.readyState !== 0) {
                        handleJoin();

                    } else {
                        setTimeout(waitForReadyState, 500);
                    }
                }

                waitForReadyState();
            }
        }
    }, [connectionRedyState]);


    useEffect(() => {
        if (videoDevices.length > 0 || audioDevices.length > 0) {
            setRequestingParticipation(false);
        }

    }, [videoDevices, audioDevices])

    function backHome() {
        handleJoin();

        if (permissions.userType === 'viewer') {
            navigate('/');
        }
    }

    useEffect(() => {
        return () => {
            const unMountExit = async () => {
                if (permissions.userType === USER_TYPE.VIEWER) {
                    // ビュワーなら退出時に配信不可の権限に変更する
                    setRequestingParticipation(false);
                    updatePermissions({canStreaming: false});
                    // ステージ参加後、参加通知を送信
                    await sendParticipationNotification(hash);
                }
                // ステージ退出
                setRequestingParticipation(false);
                leaveStage();
            }

            unMountExit();
        };
    }, []);


    return (
        <div className={"stream-settings"}>
            <div>
                <div className={'stream-settings-container'}>
                    <div className={'media-setting'}>
                        {audioDevices.length > 0 && (
                            <Icon onClick={() => toggleDeviceMute(currentAudioDevice)}>
                                {audioMuted ? <i className="fa-solid fa-microphone-slash fa-lg icon-child"></i> :
                                    <i className="fa-solid fa-microphone fa-lg icon-child"></i>}
                            </Icon>
                        )}
                        {videoDevices.length > 0 && (
                            <Icon onClick={() => toggleDeviceMute(currentVideoDevice)}>
                                {localVideoMuted ? <i className="fa-solid fa-video-slash fa-lg icon-child"></i> :
                                    <i className="fa-solid fa-video fa-lg icon-child"></i>}
                            </Icon>
                        )}

                        {permissions.canScreenShare && (
                            <Icon onClick={onClickScreenShare}>
                                {screenShareStageJoined ?
                                    <svg className='icon-child' width="2rem" height="2rem" viewBox="0 0 423 421" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M392.7 420.467L347.083 374.85H5.95V335.183H306.921L287.088 315.35H65.45C54.5417 315.35 45.2035 311.466 37.4354 303.698C29.6674 295.93 25.7833 286.592 25.7833 275.683V54.5417L0 27.7667L27.7667 0L420.467 392.7L392.7 420.467ZM184.45 213.208L148.254 176.517C146.601 179.492 145.61 182.632 145.279 185.938C144.949 189.243 144.783 192.714 144.783 196.35V236.017H184.45V213.208ZM396.667 312.375L272.213 187.921L303.45 156.683L243.95 97.1833V136.85H221.142L102.142 17.85H382.783C393.692 17.85 403.03 21.734 410.798 29.5021C418.566 37.2701 422.45 46.6083 422.45 57.5167V275.683C422.45 284.278 420.053 291.798 415.26 298.244C410.467 304.69 404.269 309.4 396.667 312.375Z"
                                            fill="black"/>
                                    </svg>
                                    :
                                    <svg className='icon-child' width="2rem" height="2rem" viewBox="0 0 438 358" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M139.125 218.625H178.875V178.875C178.875 173.244 180.78 168.523 184.589 164.714C188.398 160.905 193.119 159 198.75 159H238.5V198.75L298.125 139.125L238.5 79.5V119.25H198.75C182.187 119.25 168.109 125.047 156.516 136.641C144.922 148.234 139.125 162.312 139.125 178.875V218.625ZM59.625 298.125C48.6937 298.125 39.3359 294.233 31.5516 286.448C23.7672 278.664 19.875 269.306 19.875 258.375V39.75C19.875 28.8187 23.7672 19.4609 31.5516 11.6766C39.3359 3.89219 48.6937 0 59.625 0H377.625C388.556 0 397.914 3.89219 405.698 11.6766C413.483 19.4609 417.375 28.8187 417.375 39.75V258.375C417.375 269.306 413.483 278.664 405.698 286.448C397.914 294.233 388.556 298.125 377.625 298.125H59.625ZM0 357.75V318H437.25V357.75H0Z"
                                            fill="black"/>
                                    </svg>
                                }
                            </Icon>
                        )}

                        {videoDevices.length > 0 && (
                            <Icon onClick={() => setDisplaySettingDetail(true)}>
                                <i className="fa-solid fa-gear fa-lg icon-child"></i>
                            </Icon>
                        )}
                    </div>
                    
                    <div className="treams-setting">
                        {permissions.userType === 'viewer' && (
                            <Button onClick={backHome}
                                className={`streams-setting-button ${stageJoined ? 'red-button' : 'gray-button'}`}>
                                {stageJoined ? '退出する' : '参加中'}
                            </Button>
                        )}

                        {permissions.userType === 'publisher' && (
                            <Button onClick={exitStaging}
                                    className={`streams-setting-button ${stageJoined ? 'red-button' : 'blue-button'}`}>
                                {stageJoined ? '退出する' : '参加する'}
                            </Button>
                        )}
                        {permissions.userType === USER_TYPE.VIEWER && (
                            <>
                                {audioDevices.length === 0 && videoDevices.length === 0 ? (
                                        <Button onClick={handleRequestParticipation}
                                                className={`${requestingParticipation ? 'gray-button' : 'orange-button'} streams-setting-button application`}
                                        >
                                            {requestingParticipation ? '発言権を申請中' : '発言権を申請する'}
                                        </Button>
                                    ) :
                                    (
                                        <Button onClick={() => sendForceExitStage(hash)}
                                                className="red-button streams-setting-button">配信停止</Button>
                                    )}
                                {/*{audioDevices.length > 0 && videoDevices.length > 0 && (*/}
                                {/*    <Button onClick={handleJoin} className={stageJoined ? 'red-button' : 'blue-button'}*/}
                                {/*    width={'30'}>*/}
                                {/*    {stageJoined ? '配信を停止する' : '配信を開始する'}</Button>*/}
                                {/*)}*/}
                            </>
                        )}   
                    </div>
                    {participationRequests.map((request, index) => (
                        <div key={index} className={'request-wrap'}>
                            <p className={'request-user-name'}>{request.userName}</p>
                            <Button onClick={() => sendAllowParticipation(request.userHash)} className={'green-button'}
                                    width={'40'}>
                                承認する
                            </Button>
                            <Button onClick={() => sendDenyParticipation(request.userHash)} className={'red-button'}
                                    width={'40'}>
                                拒否する
                            </Button>
                        </div>
                    ))}
                    <div className="setting-detail-wrap"
                         style={dispalySettingDetail ? {display: 'block'} : {display: 'none'}}>
                        <div className="setting-detail-inner">
                            <p className="setting-detail-title">Stream Settings</p>
                            <div className="setting-webcam-wrap">
                                <Select options={videoDevices} onChange={updateLocalVideo} title={'Select Webcam'}/>
                            </div>
                            <div className="setting-mic-wrap">
                                <Select options={audioDevices} onChange={updateLocalAudio} title={'Select Mic'}/>
                            </div>

                            <div style={{marginTop: '20px', textAlign: 'center'}}>
                                <Button className={'blue-button'} width={'50'}
                                        onClick={() => setDisplaySettingDetail(false)}>閉じる</Button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}
