import React, {useContext, useEffect} from 'react';
import LocalVideo from "./LocalVideo";
import {StageContext} from "../contexts/StageContext";
import Participant from "./Participant";
import {LocalMediaContext} from "../contexts/LocalMediaContext";
import {ChatContext} from "../../chat/contexts/ChatContext";

export default function Streams() {
    const {participants,stageJoined} = useContext(StageContext);
    console.log(participants)

    const {videoDevices} = useContext(LocalMediaContext);
    const {userHashForSortChange} = useContext(ChatContext);

    const sortedParticipants = [...participants.entries()].sort(([key1, participant1], [key2, participant2]) => {
        // created_atに基づくソート（直近の日付を先に）
        const date1 = new Date(participant1.attributes.created_at);
        const date2 = new Date(participant2.attributes.created_at);
        return date2 - date1;
    });

    console.log(sortedParticipants)

    // userHashForSortChangeと一致する参加者、またはpriorityが1の参加者を見つける
    let specialParticipantIndex = sortedParticipants.findIndex(([key, participant]) =>
        participant.attributes.hash === userHashForSortChange);

    if (specialParticipantIndex === -1) {
        // userHashForSortChangeに一致する参加者がいない場合、priorityが1の参加者を探す
        specialParticipantIndex = sortedParticipants.findIndex(([key, participant]) =>
            participant.attributes.priority === "1");
    }

    const specialParticipant = specialParticipantIndex !== -1 ? sortedParticipants.splice(specialParticipantIndex, 1)[0] : null;

    console.log(specialParticipant)
    return (
        <div style={{marginTop: '10px'}}>
            <div>
                {specialParticipant && (
                    <Participant key={specialParticipant[0]} {...specialParticipant[1]} />
                )}
            </div>
            <div style={{display: 'flex', overflowX: 'auto', marginTop: '10px'}}>

                {videoDevices.length > 0 && <LocalVideo stageJoined={stageJoined}/>}
                {sortedParticipants.map(([key, participant]) => (
                    <Participant key={key} className={'small'} {...participant} />

                ))}
            </div>
        </div>
    );
}
