import React, {useContext, useEffect} from 'react';
import {LocalMediaContext} from '../contexts/LocalMediaContext.js';
import Video from './Video.js';
import MutedVideoScreen from "../../../components/MutedVideoScreen";

export default function LocalVideo(props) {
    const {currentVideoDevice,localVideoMuted} = useContext(LocalMediaContext);

    useEffect(() => {
        console.log(currentVideoDevice)
    }, [currentVideoDevice])

    return (
        <div className="column column-40" id="local-media" style={{minHeight: '160px'}}>
            {/*<div className="column column-40" id="local-media" style={{width: '100%'}}>*/}
            <div className="participantContainer">
                {localVideoMuted ? <MutedVideoScreen stageStream={currentVideoDevice} stageJoined={props.stageJoined}/> : <Video stageStream={currentVideoDevice} stageJoined={props.stageJoined}/>}
                {/*<Video stageStream={currentVideoDevice}/>*/}
            </div>
        </div>
    );
}
