// チャットトークンの発行
export async function fetchChatToken () {
    try {
        const response = await fetch('https://000xac2085.execute-api.ap-northeast-1.amazonaws.com/chat_test/', {
            method: 'POST',
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const bodyData = JSON.parse(data.body); // bodyフィールドをJSONとして解析
        return bodyData.token;
    } catch (error) {
        console.error('Error fetching chat token:', error);
    }
}
