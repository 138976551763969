import React, {createContext, useState, useEffect, useContext} from 'react';
import { getDevices } from '../functions/mediaDevices.js';
import { useLocalMedia } from '../hooks/useLocalMedia.js';
import {PermissionContext} from "../../permission/contexts/PermissionContext";

export const LocalMediaContext = createContext({
    hash: undefined,
    name: undefined,
    permissions: undefined,
    updatePermissions: undefined,
    audioDevices: [],
    videoDevices: [],
    currentVideoDevice: undefined,
    currentAudioDevice: undefined,
    screenShare: undefined,
    updateLocalAudio: undefined,
    updateLocalVideo: undefined,
    updateScreenShare: undefined
});

export default function LocalMediaProvider({ children }) {
    // 権限
    const {
        permissions,
        updatePermissions
    } = useContext(PermissionContext);
    const {
        hash,
        name,
        localAudio,
        localVideo,
        screenShare,
        localVideoMuted,
        setLocalAudio,
        setLocalVideo,
        setScreenShare,
        setLocalVideoMuted
    } = useLocalMedia();
    const [audioDevices, setAudioDevices] = useState([]);
    const [videoDevices, setVideoDevices] = useState([]);

    useEffect(() => {
        console.log(permissions);
        if (!permissions.canStreaming) {
            // 配信権限がなければ何もしない
            setLocalAudio(null);
            setLocalVideo(null);
            setAudioDevices([]);
            setVideoDevices([]);
            return;
        }

        const setDevices = async () => {
            const { videoDevices, audioDevices } = await getDevices();
            setLocalAudio(audioDevices[0].deviceId);
            setLocalVideo(videoDevices[0].deviceId);
            setAudioDevices(
                audioDevices.map((device) => {
                    return { label: device.label, value: device.deviceId };
                })
            );
            setVideoDevices(
                videoDevices.map((device) => {
                    return { label: device.label, value: device.deviceId };
                })
            );
        };

        setDevices();
    }, [permissions]);

    const state = {
        permissions,
        updatePermissions,
        hash,
        name,
        audioDevices,
        videoDevices,
        screenShare,
        localVideoMuted,
        currentAudioDevice: localAudio,
        currentVideoDevice: localVideo,
        updateLocalAudio: setLocalAudio,
        updateLocalVideo: setLocalVideo,
        updateScreenShare: setScreenShare,
        setLocalVideoMuted
    };

    return <LocalMediaContext.Provider value={state}>{children}</LocalMediaContext.Provider>;
}
