import React, {createContext} from "react";
import useChat from "../hoooks/useChat";

const defaultChatContext = {
    messages: [],
    participationRequests: [],
    sendMessage: undefined,
    sendRequestParticipation: undefined,
    sendAllowParticipation: undefined,
    sendDenyParticipation: undefined,
    sendForceExitStage: undefined,
    sendChangeSort: undefined,
    sendParticipationNotification: undefined,
};

export const ChatContext = createContext({
    ...defaultChatContext
});

export default function ChatProvider({ children }) {
    const {
        messages,
        participationRequests,
        sendMessage,
        sendRequestParticipation,
        sendAllowParticipation,
        sendDenyParticipation,
        sendForceExitStage,
        sendChangeSort,
        userHashForSortChange,
        sendParticipationNotification,
        connectionRedyState,
    } = useChat();

    const state = {
        messages,
        sendMessage,
        sendRequestParticipation,
        participationRequests,
        sendAllowParticipation,
        sendDenyParticipation,
        sendForceExitStage,
        sendChangeSort,
        userHashForSortChange,
        sendParticipationNotification,
        connectionRedyState,
    };

    return <ChatContext.Provider value={state}>{children}</ChatContext.Provider>;
}
