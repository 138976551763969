import React from "react";
import {USER_TYPE} from "../../../constants/permissions";
import usePermission from "../hooks/usePermission";
import {PermissionContext} from './PermissionContext';

export default function PublisherProvider({ children }) {
    const initialPermissions = {
        // ユーザータイプ
        userType: USER_TYPE.PUBLISHER,
        // 配信可否
        canStreaming: true,
        // 画面共有可否
        canScreenShare: true,
        // 参加依頼可否
        canRequestParticipation: false,
        // 参加依頼受付可否
        canReceiveRequestParticipation: true,
        // メイン配信画面選択可否
        canSelectMainStream: true,
        // 配信者の削除可否
        canDeleteStream: true,
        updatePermissions: undefined
    };

    const {permissions, updatePermissions} = usePermission(initialPermissions);

    const state = {
        permissions,
        updatePermissions
    };

    return <PermissionContext.Provider value={state}>{children}</PermissionContext.Provider>;
};
