import {useContext, useEffect, useRef, useState} from "react";
import {fetchChatToken} from "../functions/chatToken";
import {StageContext} from "../../streaming/contexts/StageContext";
import {send} from "../functions/mesage";
import {USER_TYPE} from "../../../constants/permissions";
import {ACTIONS} from "../constants/actions";
import {
    allowParticipation,
    changeStreamSort,
    denyParticipation,
    forceExitStage,
    requestParticipation,
    participationNotification,
    UserHashForMainScreen,
} from "../functions/streamEvents";
import {LocalMediaContext} from "../../streaming/contexts/LocalMediaContext";

export default function useChat() {
    // 権限・ユーザー情報
    const {
        permissions,
        updatePermissions,
        hash,
        name,
    } = useContext(LocalMediaContext);
    // ステージ管理
    const {
        changeJoinProperties
    } = useContext(StageContext);
    // チャットトークン
    const [chatToken, setChatToken] = useState('');
    // チャットコネクション
    const connectionRef = useRef(null);
    // チャットメッセージ
    const [messages, setMessages] = useState([]);
    // 参加依頼のユーザー情報
    const [requestUsers, setRequestUsers] = useState([]);
    // ステージ参加許可リスト
    const [participationRequests, setParticipationRequests] = useState([]);
    // メイン画面ユーザー
    const [userHashForSortChange, setUserHashForSortChange] = useState('');
    const [connectionRedyState,setConnectionRedyState] = useState();

    // 画面描画時にチャットトークンを取得する
    useEffect(() => {
        const initToken = async () => {
            try {
                const token = await fetchChatToken();
                setChatToken(token);
            } catch (error) {
                console.error('Error fetching chat token:', error);
                alert('コネクションエラー リロードしてください。');
            }
        };

        initToken();
    }, []);

    // チャットトークンを監視してwebsocketに接続
    useEffect(() => {
        const socket = "wss://edge.ivschat.ap-northeast-1.amazonaws.com";
        if (chatToken) {
            connectionRef.current = new WebSocket(socket, chatToken);

            connectionRef.current.onmessage = (event) => {
                const data = JSON.parse(event.data);
                console.log('Received message:', data);

                if (data.Attributes && data.Attributes.action === ACTIONS.SEND_MESSAGE) {
                    // 通常メッセージ
                    console.log('SendMessage');
                    setMessages(prevMessages => [...prevMessages, data]);
                } else if (data.Attributes && data.Attributes.action === ACTIONS.REQUEST_PARTICIPATION) {
                    // 参加リクエスト受付権限がなければ受け取らない
                    if (!permissions.canReceiveRequestParticipation) {
                        return;
                    }
                    // 参加リクエスト受信
                    console.log('RequestParticipation');
                    // 許可リストに追加
                    setParticipationRequests(prevMessages => [...prevMessages, data.Attributes]);
                } else if (data.Attributes && data.Attributes.action === ACTIONS.ALLOW_PARTICIPATION) {
                    // 参加許可受信
                    console.log('AllowParticipation');
                    // 存在しているparticipationRequestsから対象を削除する
                    setParticipationRequests(currentRequests =>
                        currentRequests.filter(request => request.userHash !== data.Attributes.userHash)
                    );
                    if (data.Attributes.userHash !== hash) {
                        // ハッシュが自分でなければ何もしない
                        return;
                    }

                    // 自分なら権限を更新して参加
                    updatePermissions({ canStreaming: true });
                    const capabilities = ["PUBLISH", "SUBSCRIBE"];
                    const attributes = {
                        "hash": hash,
                        "name": name
                    };
                    changeJoinProperties(capabilities, attributes);
                    sendUserHashForMainScreen(userHashForSortChange);
                } else if (data.Attributes && data.Attributes.action === ACTIONS.DENY_PARTICIPATION) {
                    // 参加拒否受信
                    console.log('DenyParticipation');
                    // 存在しているparticipationRequestsから対象を削除する
                    setParticipationRequests(currentRequests =>
                        currentRequests.filter(request => request.userHash !== data.Attributes.userHash)
                    );
                } else if (data.Attributes && data.Attributes.action === ACTIONS.EXIT_STAGE) {
                    // ステージ退出（強制）
                    console.log('ExitStage');
                    if (data.Attributes.userHash !== hash) {
                        // ハッシュが自分でなければ何もしない
                        return;
                    }

                    // 自分なら視聴権限で参加し直し
                    updatePermissions({ canStreaming: false });
                    const capabilities = ["SUBSCRIBE"];
                    const attributes = {
                        "hash": hash,
                        "name": name
                    };
                    changeJoinProperties(capabilities, attributes);
                    sendUserHashForMainScreen(userHashForSortChange);
                } else if (data.Attributes && data.Attributes.action === ACTIONS.SORT_CHANGE) {
                    // 並び順変更
                    console.log('SortChange');
                    // ハッシュをメイン画面になるように
                    setUserHashForSortChange(data.Attributes.userHash);

                    // if (data.Attributes.userHash !== hash) {
                    //     // ハッシュが自分でなければ何もしない
                    //     return;
                    // }
                    // // 自分ならpriorityを1にして入り直し
                    // const capabilities = ["PUBLISH", "SUBSCRIBE"];
                    // const attributes = {
                    //     "hash": hash,
                    //     "name": name,
                    //     "priority": "1"
                    // };
                    // changeJoinProperties(capabilities, attributes);
                }else if (data.Attributes && data.Attributes.action === ACTIONS.PARTICIPATION_NOTIFICATION) {
                    console.log('Stage join notification');
                    console.log(data.Attributes.userHash);

                    // if (permissions.userType === USER_TYPE.PUBLISHER) {
                        // パブリッシャーである場合、userHashForSortChangeの値をチャットで送信
                        sendUserHashForMainScreen(userHashForSortChange);
                    // }
                }else if (data.Attributes && data.Attributes.action === ACTIONS.USERHASHFORSORTCHANGE) {
                    console.log('main screen notification');
                    console.log(data.Attributes.userHashForSortChange);
                    // userHashForSortChangeの値が存在し、現在の値と異なる場合にのみ更新する
                    if (data.Attributes.userHashForSortChange && data.Attributes.userHashForSortChange !== userHashForSortChange) {
                        setUserHashForSortChange(data.Attributes.userHashForSortChange);
                    }
                }
                else {
                    console.log('else');
                    console.log('Stage participation message received:', data.Content);
                }
            };

            setConnectionRedyState(connectionRef.current);

            // Clean up WebSocket connection on component unmount
            return () => {
                if (connectionRef.current) {
                    connectionRef.current.close();
                }
            };
        }
    }, [chatToken]);

    function sendMessage(message) {
        send(name, hash, connectionRef.current, message);
    }

    function sendRequestParticipation() {
        requestParticipation(name, hash, connectionRef.current);
    }

    function sendAllowParticipation(userHash) {
        allowParticipation(userHash, connectionRef.current);
    }

    function sendDenyParticipation(userHash) {
        denyParticipation(userHash, connectionRef.current);
    }
    
    function sendForceExitStage(userHash) {
        forceExitStage(userHash, connectionRef.current);
    }
    
    function sendChangeSort(userHash) {
        changeStreamSort(userHash, connectionRef.current);
    }
    
    function sendParticipationNotification(userHash) {
        participationNotification(userHash, connectionRef.current);
    }

    function sendUserHashForMainScreen(userHashForSortChange) {
        console.log('sendUserHashForMainScreen');
        UserHashForMainScreen(userHashForSortChange, connectionRef.current);
    }

    return {
        messages,
        name,
        hash,
        participationRequests,
        sendMessage,
        sendRequestParticipation,
        sendAllowParticipation,
        sendDenyParticipation,
        sendForceExitStage,
        sendChangeSort,
        userHashForSortChange,
        sendParticipationNotification,
        connectionRedyState,
    }
}
