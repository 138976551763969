export async function getParticipantToken(capabilities = [], attributes = {}) {
    attributes.created_at = new Date();
    attributes.priority = attributes.priority ?? '0';

    let requestBody = JSON.stringify({
        capabilities: capabilities,
        attributes: attributes
    });

    try {
        const response = await fetch('https://y6vexjiark.execute-api.ap-northeast-1.amazonaws.com/ivs-test/participantToken', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // 必要に応じて他のヘッダー
            },
            body: requestBody,
        });
        const data = await response.json();
        if (data && data.token) {
            console.log(data.token);
            return data.token.token; // 修正: 正しいトークン文字列へのアクセス
        } else {
            throw new Error('Failed to fetch the token');
        }
    } catch (error) {
        console.error('Error fetching token:', error);
        throw error; // 外部のエラーハンドリングに渡す
    }
}
