import React, { useState, useRef, useContext, useEffect } from 'react';
import { LocalMediaContext } from '../contexts/LocalMediaContext.js';
import Strategy from '../functions/strategy.js';
import { Stage, StageConnectionState, StageEvents } from "amazon-ivs-web-broadcast";
import {getParticipantToken} from "../functions/participantToken";

export default function useStage() {
    // 選択中のデバイス
    const { currentVideoDevice, currentAudioDevice } = useContext(LocalMediaContext);
    // ステージ参加状態
    const [stageJoined, setStageJoined] = useState(false);
    const stageJoinedRef = useRef(stageJoined);
    // 参加者一覧
    const [participants, setParticipants] = useState(new Map());
    // ローカルユーザー
    const [localParticipant, setLocalParticipant] = useState({});

    const stageRef = useRef(undefined);
    const strategyRef = useRef(new Strategy(currentAudioDevice, currentVideoDevice));

    useEffect(() => {
        strategyRef.current.updateMedia(currentAudioDevice, currentVideoDevice);
        if (stageRef.current && stageJoined) {
            stageRef.current.refreshStrategy();
        }
    }, [currentAudioDevice, currentVideoDevice]);

    useEffect(() => {
        stageJoinedRef.current = stageJoined;
    }, [stageJoined])

    useEffect(() => {
        return () => leaveStage();
    }, []);

    const handleParticipantJoin = (participantInfo) => {
        if (isLocalParticipant(participantInfo)) {
            setLocalParticipant(participantInfo);
        } else {
            const participant = createParticipant(participantInfo);
            setParticipants(new Map(participants.set(participant.id, participant)));
        }
    };

    const handleParticipantLeave = (participantInfo) => {
        if (isLocalParticipant(participantInfo)) {
            setLocalParticipant({});
        } else {
            if (participants.delete(participantInfo.id)) {
                setParticipants(new Map(participants));
            }
        }
    };

    const handleMediaAdded = (participantInfo, streams) => {
        console.log(participantInfo)
        if (!isLocalParticipant(participantInfo)) {
            const { id } = participantInfo;
            let participant = participants.get(id);
            participant = { ...participant, streams: [...streams, ...participant.streams] };
            setParticipants(new Map(participants.set(id, participant)));
        }
    };

    const handleMediaRemoved = (participantInfo, streams) => {
        if (!isLocalParticipant(participantInfo)) {
            const { id } = participantInfo;
            let participant = participants.get(id);
            const newStreams = participant.streams.filter(
                (existingStream) => !streams.find((removedStream) => existingStream.id === removedStream.id)
            );
            participant = { ...participant, streams: newStreams };
            setParticipants(new Map(participants.set(id, participant)));
        }
    };

    const handleParticipantMuteChange = (participantInfo, stream) => {
        if (!isLocalParticipant(participantInfo)) {
            const { id } = participantInfo;
            let participant = participants.get(id);
            participant = { ...participant, ...participantInfo };
            setParticipants(new Map(participants.set(id, participant)));
        }
    };

    const handleConnectionStateChange = (state) => {
        if (state === StageConnectionState.CONNECTED) {
            setStageJoined(true);
        } else if (state === StageConnectionState.DISCONNECTED) {
            setStageJoined(false);
        }
    };

    function leaveStage() {
        if (stageRef.current) {
            stageRef.current.leave();
            console.log('ここでleaveしてます')
        }
    }

    async function changeJoinProperties(capabilities = [], attributes = {}) {
        // useStateは関数宣言時の値をラップするのでrefで確認する
        if (!stageJoinedRef.current) {
            // 未参加の場合は普通に参加
            joinStage(capabilities, attributes);
        } else {
            // 参加済みの場合はleaveしてから参加
            leaveStage();
            // leave完了まで待つ
            setTimeout(() => {
                joinStage(capabilities, attributes);
            }, 1000);
        }
    }

    async function joinStage(capabilities = [], attributes = {}) {
        const token = await getParticipantToken(capabilities, attributes);

        try {
            const stage = new Stage(token, strategyRef.current);
            stage.on(StageEvents.STAGE_CONNECTION_STATE_CHANGED, handleConnectionStateChange);
            stage.on(StageEvents.STAGE_PARTICIPANT_JOINED, handleParticipantJoin);
            stage.on(StageEvents.STAGE_PARTICIPANT_LEFT, handleParticipantLeave);
            stage.on(StageEvents.STAGE_PARTICIPANT_STREAMS_ADDED, handleMediaAdded);
            stage.on(StageEvents.STAGE_PARTICIPANT_STREAMS_REMOVED, handleMediaRemoved);
            stage.on(StageEvents.STAGE_STREAM_MUTE_CHANGED, handleParticipantMuteChange);

            console.log(stage)

            stageRef.current = stage;
            console.log(stageRef.current)

            await stageRef.current.join();
            setStageJoined(true);
            console.log('joined')

            // If we are able to join we know we have a valid token so lets cache it
            sessionStorage.setItem('stage-token', token);
        } catch (err) {
            console.error('Error joining stage', err);
            alert(`Error joining stage: ${err.message}`);
        }
    }

    return { joinStage, stageJoined, leaveStage, changeJoinProperties, participants };
}

function createParticipant(participantInfo) {
    return {
        ...participantInfo,
        streams: [],
    };
}

function isLocalParticipant(info) {
    return info.isLocal;
}
