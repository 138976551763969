import {useContext, useEffect, useState} from "react";
import {ChatContext} from "../contexts/ChatContext";

export default function Chat() {
    const {messages, sendMessage, name, setName, hash} = useContext(ChatContext);

    // チャットのインプット
    const [messageInput, setMessageInput] = useState('');

    async function submitMessage() {
        const messageArray = [messageInput];
        const messageContents = messageArray.map(str => str.replace(/\n/g, '<br>'));
        await sendMessage(messageContents[0]);
        setMessageInput('');
    };


    return (
        <div>
            <div className={'chat-wrap'}>
                <p className="chat-title-text">チャット</p>

                <div className="chat-content">
                    <div className="chat-inner">
                        {messages.length === 0 && <p>チャットはここに表示されます</p>}
                        {messages.map((message, index) => (
                            <div className="chat-item" key={index}>
                                {message.Attributes.userHash === hash ? (
                                    <>
                                        <div className="chat-user-info">
                                            <div className="chat-icon">
                                                <div className="damy-icon"></div>
                                            </div>
                                            <p className="chat-user-name">{message.Attributes.userName}</p>
                                        </div>
                                        <div className="chat-text"　dangerouslySetInnerHTML={{ __html: message.Content }}/>
                                        <p className="chat-time">{message.Attributes.sentAt}</p>
                                    </>
                                ) : (
                                    <>
                                        <div className="chat-user-info">
                                            <div className="chat-icon">
                                                <div className="damy-icon"></div>
                                            </div>
                                            <p className="chat-user-name">{message.Attributes.userName}</p>
                                        </div>
                                        <div className="chat-text"　dangerouslySetInnerHTML={{ __html: message.Content }}/>
                                        <p className="chat-time">{message.Attributes.sentAt}</p>
                                    </>
                                )}
                            </div>
                        ))}
                    </div>

                    {/*<div>*/}
                    {/*    <input*/}
                    {/*        type="text"*/}
                    {/*        value={messageInput}*/}
                    {/*        onChange={(e) => setMessageInput(e.target.value)}*/}
                    {/*    />*/}
                    {/*    <button onClick={() => sendMessage(messageInput)}>send</button>*/}
                    {/*</div>*/}
                </div>

                <div className="chat-send-wrap">
                    <div className="chat-send-inner">
                        <div className="chat-send-container">
                            <div className="chat-input-area">
                                <textarea
                                    value={messageInput}
                                    onChange={(e) => setMessageInput(e.target.value)}
                                    placeholder={'ここにメッセージを入力してください'}
                                ></textarea>
                            </div>
                            <div className="chat-send-icon" onClick={submitMessage}>
                                <i className="fa-solid fa-paper-plane fa-lg" style={{color:'#0877c6'}}></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        // <div className='chat-wrap'>
        //     <p className="chat-title-text">チャット</p>
        //
        //     <div className="chat-content">
        //         <div className="chat-inner">
        //             <div className="chat-item">
        //                 <div className="chat-user-info">
        //                     <div className="chat-icon">
        //                         <div className="damy-icon"></div>
        //                     </div>
        //                     <p className="chat-user-name">名前</p>
        //                 </div>
        //                 <div
        //                     className="chat-text">参加は有料になります。購入がまだの方は画面下の購入画面より参加種別を選んでお支払いにお進みください。
        //                 </div>
        //                 <p className="chat-time">14:00</p>
        //             </div>
        //         </div>
        //     </div>
        //
        //     <div className="chat-send-wrap">
        //         <div className="chat-send-inner">
        //             <p>メッセージの送信</p>
        //             <div className="chat-send-container">
        //                 <div className="chat-input-area"><textarea></textarea></div>
        //                 <div className="chat-send-icon">
        //                     <svg viewBox="0 0 330 330" fill="none" xmlns="http://www.w3.org/2000/svg">
        //                         <path d="M76 252V186.75L163.158 165L76 143.25V78L283 165L76 252Z" fill="#0070C0"/>
        //                         <circle cx="165" cy="165" r="155" stroke="#0070C0" stroke-width="20"/>
        //                     </svg>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>


    )
}
