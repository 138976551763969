export const ACTIONS = {
    SEND_MESSAGE: 'SendMessage',
    REQUEST_PARTICIPATION: 'RequestParticipation',
    ALLOW_PARTICIPATION: 'AllowParticipation',
    DENY_PARTICIPATION: 'DenyParticipation',
    EXIT_STAGE: 'ExitStage',
    SORT_CHANGE: 'SortChange',
    PARTICIPATION_NOTIFICATION: 'participationNotification',
    USERHASHFORSORTCHANGE: 'userHashForSortChange'
}
