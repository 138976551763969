import React, { useState, useRef, useContext, useEffect } from 'react';
import { getScreenshare } from '../functions/mediaDevices.js';
import { LocalMediaContext } from '../contexts/LocalMediaContext.js';
import Strategy from '../functions/strategy.js';
import { Stage, StageConnectionState, StageEvents, SubscribeType } from "amazon-ivs-web-broadcast";
import {getParticipantToken} from "../functions/participantToken";

export default function useScreenShareStage() {
    // 画面共有参加状態
    const [screenShareStageJoined, setScreenShareStageJoined] = useState(false);
    // 画面共有のストリームと権限情報
    const {
        hash,
        name,
        screenShare,
        updateScreenShare
    } = useContext(LocalMediaContext);

    const stageRef = useRef(undefined);
    const strategyRef = useRef(new Strategy(undefined, undefined, SubscribeType.NONE));

    useEffect(() => {
        // screenShare.video と screenShare.audio から MediaStreamTrack を取得
        const videoTrack = screenShare.video;
        const audioTrack = screenShare.audio;
        console.log(screenShare.video);

        console.log(videoTrack);
        console.log(audioTrack);
        // updateMedia を適切に呼び出し
        strategyRef.current.updateMedia(audioTrack, videoTrack);
        console.log(strategyRef.current);
        if (stageRef.current && screenShareStageJoined) {
            stageRef.current.refreshStrategy();
        }
    }, [screenShare, screenShareStageJoined]);

    const handleConnectionStateChange = (state) => {
        if (state === StageConnectionState.CONNECTED) {
            setScreenShareStageJoined(true);
        } else if (state === StageConnectionState.DISCONNECTED) {
            setScreenShareStageJoined(false);
        }
    };

    function unPublishScreenShare() {
        if (stageRef.current) {
            stageRef.current.leave();
            updateScreenShare(undefined);
        }
    }

    async function publishScreenShare() {
        const capabilities = ["PUBLISH"];
        const attributes = {
            "priority": "1",
            "hash": hash,
            "name": name
        };
        const token = await getParticipantToken(capabilities, attributes);

        try {
            console.log('Starting screen share...');
            const mediaStream = await getScreenshare();
            const screenShareVideo = mediaStream.getVideoTracks()[0];
            const screenShareAudio = mediaStream.getAudioTracks().length > 0 ? mediaStream.getAudioTracks()[0] : null;

            console.log('Screen share video track:', screenShareVideo);
            console.log('Screen share audio track:', screenShareAudio);

            updateScreenShare(screenShareVideo, 'video');
            if (screenShareAudio) {
                updateScreenShare(screenShareAudio, 'audio');
            }

            // ブラウザ機能での画面共有停止時のハンドリング
            if (screenShareVideo) {
                screenShareVideo.onended = () => {
                    console.log('画面共有が停止されました。');
                    unPublishScreenShare();
                };
            }
        } catch (e) {
            console.error('Screen share error:', e);
            return;
        }

        try {
            const stage = new Stage(token, strategyRef.current);
            console.log(stage);
            stage.on(StageEvents.STAGE_CONNECTION_STATE_CHANGED, handleConnectionStateChange);

            stageRef.current = stage;

            await stageRef.current.join();
            console.log('Joined screen share stage successfully');
            sessionStorage.setItem('stage-screenshare-token', token);
        } catch (err) {
            console.error('Error joining screen share stage:', err);
        }
    }


    return { publishScreenShare, screenShareStageJoined, unPublishScreenShare };
}