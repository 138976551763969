import React, {useRef, useEffect, useContext} from 'react';
import Video from './Video.js';
import {StreamType} from "amazon-ivs-web-broadcast";
import Placeholder from "../../../components/Placeholder";
import Button from "../../../components/Button";
import {ChatContext} from "../../chat/contexts/ChatContext";
import {USER_TYPE} from "../../../constants/permissions";
import {LocalMediaContext} from "../contexts/LocalMediaContext";
import MutedVideoScreen from "../../../components/MutedVideoScreen";

export default function Participant({id, className, userId, videoStopped, audioMuted, attributes, streams}) {
    const {sendForceExitStage, sendChangeSort} = useContext(ChatContext);
    const {permissions} = useContext(LocalMediaContext);
    const videoStream = streams.find((stream) => stream.streamType === StreamType.VIDEO);
    const audioStream = streams.find((stream) => stream.streamType === StreamType.AUDIO);

    const audioRef = useRef(undefined);

    useEffect(() => {
        if (audioRef.current && audioStream) {
            audioRef.current.srcObject = new MediaStream([audioStream.mediaStreamTrack]);
        }
    }, [audioRef, audioStream]);

    const style =
        className === 'small'
            ? {minHeight: '160px', marginLeft: '30px'}
            : {};

    return (
        <div className="column column-40" id={id} style={style}>
        <div className={`participantContainer ${className !== 'small' ? 'topScreenContainer' : 'lowScreenContainer'}`}>
                {permissions.userType === USER_TYPE.PUBLISHER ?
                    <a style={{display:'block'}} onClick={(e) => {
                        e.preventDefault();
                        sendChangeSort(attributes.hash)
                    }}>
                        {videoStream && !videoStopped ? <Video stageStream={videoStream} className={className}/> :
                            <MutedVideoScreen stageStream={videoStream} className={className}/>}
                        <audio ref={audioRef} autoPlay/>
                        {/*{audioMuted ? <span><i className="fa-solid fa-microphone-slash"></i></span> : undefined}*/}
                    </a>
                    :
                    <div>
                        {videoStream && !videoStopped ? <Video stageStream={videoStream} className={className}/> :
                            <MutedVideoScreen stageStream={videoStream} className={className}/>}
                        <audio ref={audioRef} autoPlay/>
                        {/*{audioMuted ? <span><i className="fa-solid fa-microphone-slash"></i></span> : undefined}*/}
                    </div>
                }
                <div className="user-info-container">
                    <div className="user-name-container">
                        <p style={{fontSize: '1.4rem'}}>{attributes.name}</p>
                        {audioMuted ? <span><i className="fa-solid fa-microphone-slash fa-sm"></i></span> : undefined}
                    </div>
                    {permissions.userType === USER_TYPE.PUBLISHER &&
                        <Button
                            onClick={() => sendForceExitStage(attributes.hash)}
                            className={'red-button user-info-button'}
                        >
                            {/*<i className="fa-solid fa-arrow-right-from-bracket"></i>*/}
                            退出
                        </Button>
                    }
                </div>
            </div>
        </div>
    );
}
