import {ACTIONS} from "../constants/actions";

export function send (userName, userHash, connection, message) {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    const payload = {
        "Action": "SEND_MESSAGE",
        "RequestId": "HOGE",
        "Content": message,
        "Attributes": {
            "action": ACTIONS.SEND_MESSAGE,
            "userName": userName,
            "userHash": userHash,
            "sentAt":`${hours}:${minutes}`
        }
    };
    const testJson = JSON.stringify(payload)
    console.log(testJson)
    console.log(JSON.parse(testJson))
    connection.send(JSON.stringify(payload));
}
