import StageStatus from "../../components/StageStatus";
import React, {} from "react";
import StreamSettings from "../../features/streaming/components/StreamSettings";
import LocalMediaProvider from "../../features/streaming/contexts/LocalMediaContext";
import StageProvider from "../../features/streaming/contexts/StageContext";
import Streams from "../../features/streaming/components/Streams";
import ChatProvider from "../../features/chat/contexts/ChatContext";
import Chat from "../../features/chat/components/Chat";
import PublisherProvider from "../../features/permission/contexts/PublisherContext";

export default function StagePublisherPage() {

    return (
        <PublisherProvider>
            <LocalMediaProvider>
                <StageProvider>
                    <ChatProvider>
                        <div className={'distribution-wrap'}>
                            <div className={'video-wrap'}>
                                <div className={'streams-wrap'}>
                                    <StageStatus/>
                                    <StreamSettings/>
                                </div>
                                <Streams/>
                            </div>
                            <div className={'chat-flex-item'}>
                                <Chat/>
                            </div>
                        </div>
                    </ChatProvider>
                </StageProvider>
            </LocalMediaProvider>
        </PublisherProvider>
    );
}
