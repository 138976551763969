import { Routes, Route } from 'react-router-dom';
import InputNamePage from '../pages/Publisher/InputNamePage';
import SelectSourcePage from "../pages/Publisher/SelectSourcePage";
import StagePublisherPage from "../pages/Publisher/StagePublisherPage";
import TopPage from "../pages/Viewer/TopPage";
import StageViewerPage from "../pages/Viewer/StageViewerPage";

function AppRoutes() {
    return (
        <Routes>
            <Route path="/publisher/input-name" element={<InputNamePage />} />
            <Route path="/publisher/select-source" element={<SelectSourcePage />} />
            <Route path="/publisher" element={<StagePublisherPage />} />
            <Route path="/" element={<TopPage />} />
            <Route path="/viewer" element={<StageViewerPage />} />
        </Routes>
    );
}

export default AppRoutes;
