import {useContext, useEffect, useState} from 'react';
import {getCamera, getDevices, getMic} from '../functions/mediaDevices.js';
import {getScreenshare} from '../functions/mediaDevices.js';
import {LocalStageStream, StreamType} from "amazon-ivs-web-broadcast";
import {PermissionContext} from "../../permission/contexts/PermissionContext";
import {generateHash} from "../../../utils/hash";
import {useLocation} from "react-router-dom";

export function useLocalMedia() {
    const location = useLocation();
    // 権限
    const {permissions} = useContext(PermissionContext);
    // ユーザー名
    const [name, setName] = useState(location.state ? location.state.name : 'UnknownUser');
    // ユーザーハッシュ
    const [hash, setHash] = useState('');
    // ローカルの映像
    const [localVideo, setLocalVideo] = useState(undefined);
    // ローカルの音声
    const [localAudio, setLocalAudio] = useState(undefined);
    // todo コンテキストで管理してしまってるので、こっちに移したい
    // // 音声メディア一覧
    // // const [audioDevices, setAudioDevices] = useState([]);
    // // 動画メディア一覧
    // const [videoDevices, setVideoDevices] = useState([]);
    // 画面共有
    const [screenShare, setScreenShare] = useState({ video: undefined, audio: undefined });
    const [localVideoMuted,setLocalVideoMuted] = useState(undefined);

    // ハッシュを生成する
    useEffect(() => {
        const initHash = async () => {
            try {
                const hash = await generateHash();
                setHash(hash);
            } catch (error) {
                console.error('Error fetching chat token:', error);
                alert('コネクションエラー リロードしてください。');
            }
        };

        initHash();
    }, []);

    function createScreenShare(track, type) {
        console.log(`Updating screen share for ${type}:`, track);

        if (type === 'video') {
            setScreenShare(prevState => ({
                ...prevState,
                video: new LocalStageStream(track)
            }));
        } else if (type === 'audio') {
            setScreenShare(prevState => ({
                ...prevState,
                audio: new LocalStageStream(track)
            }));
        }
    }
    // async function createScreenShare() {
    //     console.log('createScreenShare start');
    //     if (!permissions.canScreenShare) {
    //         console.log('Screen share permission denied.');
    //         setScreenShare({ video: undefined, audio: undefined });
    //         return;
    //     }

    //     try {
    //         const mediaStream = await getScreenshare();
    //         console.log('MediaStream obtained:', mediaStream);

    //         if (!mediaStream) {
    //             console.log('Failed to obtain MediaStream.');
    //             setScreenShare({ video: undefined, audio: undefined });
    //             return;
    //         }

    //         const videoTrack = mediaStream.getVideoTracks()[0];
    //         const audioTrack = mediaStream.getAudioTracks()[0];

    //         setScreenShare({
    //             video: videoTrack ? new LocalStageStream(videoTrack) : undefined,
    //             audio: audioTrack ? new LocalStageStream(audioTrack) : undefined
    //         });
    //     } catch (error) {
    //         console.error('Error creating screen share:', error);
    //         setScreenShare({ video: undefined, audio: undefined });
    //     }
    // }



    async function setLocalVideoFromId(id) {
        if (!permissions.canStreaming || !id) {
            // ストリーム権限がなければundefinedにする、ストリームが残っていれば停止
            if (localVideo) {
                localVideo.mediaStreamTrack.stop();
            }
            setLocalVideo(undefined);
            return;
        }
        const videoTrack = await getCamera(id);
        createLocalStream(videoTrack);
    }

    async function setLocalAudioFromId(id) {
        if (!permissions.canStreaming || !id) {
            // ストリーム権限がなければundefinedにする、ストリームが残っていれば停止
            if (localAudio) {
                localAudio.mediaStreamTrack.stop();
            }
            setLocalAudio(undefined);
            return;
        }
        const audioTrack = await getMic(id);
        createLocalStream(audioTrack);
    }

    function createLocalStream(track) {
        if (!permissions.canStreaming || !track) {
            // ストリーム権限がなければ何もしない
            return;
        }
        if (!track) {
            console.warn('tried to set local media with a null track');
            return;
        }
        const stream = new LocalStageStream(track, { simulcast: { enabled: true } });
        if (stream.streamType === StreamType.VIDEO) {
            setLocalVideo(stream);
        } else {
            setLocalAudio(stream);
        }
    }

    return {
        hash,
        name,
        localAudio,
        localVideo,
        screenShare,
        localVideoMuted,
        // audioDevices,
        // videoDevices,
        setLocalAudio: setLocalAudioFromId,
        setLocalVideo: setLocalVideoFromId,
        setScreenShare: createScreenShare,
        setLocalVideoMuted
    };
}