import {useState} from "react";

export default function usePermission(initialPermissions) {
    // 権限
    const [permissions, setPermissions] = useState(initialPermissions);

    // permissions の更新関数
    const updatePermissions = (newPermissions) => {
        setPermissions(prevPermissions => ({
            ...prevPermissions,
            ...newPermissions
        }));
    };

    return { permissions, updatePermissions };
}
