import {useContext} from "react";
import {StageContext} from "../features/streaming/contexts/StageContext";

export default function StageStatus() {
    // let status = 'idle';
    const {stageJoined} = useContext(StageContext);

    return (
        <div className={`status stage-status ${stageJoined ? 'stage-participant-true' : 'stage-participant-false'}`}>
            <div>
                <p>{stageJoined ? 'ONLINE' : 'OFFLINE'}</p>
            </div>
        </div>
    )
        ;
}
