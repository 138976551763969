import React from "react";
import {USER_TYPE} from "../../../constants/permissions";
import usePermission from "../hooks/usePermission";
import {PermissionContext} from "./PermissionContext";

export default function ViewerProvider({ children }) {
    const initialPermissions = {
        // ユーザータイプ
        userType: USER_TYPE.VIEWER,
        // 配信可否
        canStreaming: false,
        // 画面共有可否
        canScreenShare: false,
        // 参加依頼可否
        canRequestParticipation: false,
        // 参加依頼受付可否
        canReceiveRequestParticipation: false,
        // メイン配信画面選択可否
        canSelectMainStream: false,
        // 配信者の削除可否
        canDeleteStream: false,
        updatePermissions: undefined
    };

    const {permissions, updatePermissions} = usePermission(initialPermissions);

    const state = {
        permissions,
        updatePermissions
    };

    return <PermissionContext.Provider value={state}>{children}</PermissionContext.Provider>;
};
