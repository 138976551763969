// ハッシュ値の生成
export async function generateHash () {
    const randomString = Math.random().toString(36).substring(2, 15);
    const timestamp = new Date().getTime().toString();
    const combinedString = randomString + timestamp;

    // ブラウザ環境でSHA-256ハッシュを生成する
    let hash = crypto.subtle.digest('SHA-256', new TextEncoder().encode(combinedString))
        .then(hashBuffer => {
            const hashArray = Array.from(new Uint8Array(hashBuffer));
            const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
            console.log('Generated Hash:', hashHex); // ハッシュ値をコンソールに表示
            return hashHex;
        })
        .catch(error => {
            console.error('Error generating hash:', error);
            return null;
        });

    return hash;
};
