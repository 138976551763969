import React, { useRef, useEffect } from 'react';

export default function Video({ stageStream , className ,stageJoined }) {
    const videoRef = useRef(null);

    useEffect(() => {
        console.log(stageStream);
        if (videoRef.current && stageStream) {
            videoRef.current.srcObject = new MediaStream([stageStream.mediaStreamTrack]);
        }
    }, [videoRef, stageStream]);


    return <video ref={videoRef} className={(className === 'small') || (stageJoined) ? 'low-screen' : 'top-screen'} autoPlay playsInline style={{background: '#666',  height: 'auto', borderRadius:'20px',aspectRatio:'16/9'}} />;
}
